<template>
  <section class="my-5">
    <h2 class="text-center mb-4 fs-1">ウェビナー一覧</h2>

    <MDBContainer lg id="info_list">
      <MDBListGroup flush>
        <MDBListGroupItem tag="router-link" :to="'/webinar/' + webinar.public_id" action v-for="webinar in webinar_list" :key="webinar.public_id">
          <div class="d-flex flex-md-row flex-column">
            <div class="webinar_date">{{ dayjs(webinar.webinar_start_at).format('YYYY年M月D日 H:mm') }}〜{{
                dayjs(webinar.webinar_close_at).isValid() ? dayjs(webinar.webinar_close_at).format('H:mm') : ''
              }}
            </div>
            <div class="webinar_title flex-grow-1">{{ webinar.webinar_title }}</div>
          </div>
          <div class="text-end">
            <span :class="'bg_' + webinar.genre_key" v-if="webinar.genre_key">{{ webinar.genre_name }}</span>
          </div>
        </MDBListGroupItem>
      </MDBListGroup>
    </MDBContainer>
  </section>
</template>

<script>
import _ from 'lodash';
import {Api, Http} from '@/utiles/ApiConnect';
import {MDBListGroup, MDBListGroupItem, MDBContainer} from 'mdb-vue-ui-kit';
import dayjs from 'dayjs';

export default {
  data: function() {
    return {
      webinar_list: [],
    };
  },

  components: {
    MDBListGroup,
    MDBListGroupItem,
    MDBContainer,
  },

  /***
   * ライフサイクル
   */
  mounted: function() {
    this.getWebinarList();
  },

  /***
   * メソッド
   */
  methods: {
    // Webinarお知らせの取得
    getWebinarList: function() {
      // APIへ接続する。
      Api.connect(
          // 実行するメソッド名
          'Webinar.Search',
          // 引数の指定
          {},
      )
          // 実行結果の取得
          .done(function(result) {
            let webinar_list = _.sortBy(result.data, ['webinar_start_at']);
            this.webinar_list = webinar_list;
          }.bind(this));
    },
  },
};
</script>

<style lang="scss">
@import "../../node_modules/mdb-vue-ui-kit/src/scss/index.free";

#info_list {
  font-size: 1.2rem;
  @include media-breakpoint-down(md) {
    font-size: 1rem;
  }

  .webinar_date {
    @include media-breakpoint-up(md) {
      width: 180px;
    }
  }
}
</style>
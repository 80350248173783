<template>
  <MDBNavbar expand="lg" light container="lg" classContainer="py-0" position="sticky">
    <MDBNavbarBrand tag="router-link" to="/" ><img src="@/assets/header.png" class="w_logo" alt="グリーンモールディングウェビナーウィーク"></MDBNavbarBrand>
    <MDBNavbarToggler
        @click="collapse1 = !collapse1"
        target="#navbarSupportedContent"
    ></MDBNavbarToggler>
    <MDBCollapse v-model="collapse1" id="navbarSupportedContent">
      <div class="d-flex flex-column ms-auto link-area">
        <div class="d-flex flex-column flex-lg-row justify-content-between align-items-center text-center mb-2 mt-5 mt-lg-0">
          <div><a href="https://and-on.net/" id="andon_link"><img src="@/assets/rogo_andon.png" alt="andon" style="width:130px"></a></div>
          <div v-if="isLogin"><a href="https://webinarweek.net/registration/" id="signup_link">受講者登録はこちら</a></div>
          <div v-else style="color: #C4231A">ようこそ、{{ member_info.name }}さん</div>
        </div>
        <div class="d-flex flex-column flex-lg-row justify-content-between align-items-center text-center mb-10 mb-lg-0">
          <div>
            <router-link to="/">TOP</router-link>
          </div>
          <div>
            <router-link to="/faq">FAQ</router-link>
          </div>
          <div><a href="https://webinarweek.net/mypage/" id="user_login">受講者用マイページ（ログイン）</a></div>
        </div>
      </div>
    </MDBCollapse>
  </MDBNavbar>
</template>

<script>
import {
  MDBNavbar,
  MDBNavbarToggler,
  MDBNavbarBrand,
  MDBCollapse,
} from 'mdb-vue-ui-kit';
import {ref} from 'vue';
import _ from 'lodash';
import {Api, Http} from '@/utiles/ApiConnect';

export default {
  data: function() {
    return {
      member_info: [],
    };
  },

  components: {
    MDBNavbar,
    MDBNavbarToggler,
    MDBNavbarBrand,
    MDBCollapse,
  },

  computed: {
    isLogin() {
      return _.isEmpty(this.member_info);
    },
  },

  setup() {
    const collapse1 = ref(false);
    return {
      collapse1,
    };
  },

  mounted: function() {
    this.getMemberInfo();
    this.$nextTick(function() {
      $('a').on('click', function() {
        const $target = $('button[aria-controls="#navbarSupportedContent"]')
        if ($target.attr('aria-expanded') == 'true') {
          $target.trigger('click')
        }
      });
    });
  },

  methods: {
    // ユーザー情報取得
    getMemberInfo: function() {
      // APIへ接続する。
      Api.connect(
          // 実行するメソッド名
          'Member.Info',
          // 引数の指定
          {},
      )
          // 実行結果の取得
          .done(function(result) {
            this.member_info = result.data;
          }.bind(this));
    }
  },
};
</script>

<style lang="scss">
@import "./node_modules/mdb-vue-ui-kit/src/scss/index.free.scss";

.navbar {
  background-color: #B5CC24;
  padding: 0;
}

.navbar-brand img {
  width: 230px;
}

#navbarSupportedContent {
  a {
    color: #000000;
    cursor: pointer;
  }

  a:hover {
    opacity: .3;
    transition: .4s;
  }

  #exhibitor_login {
    border: 1px solid #fff;
    padding: 2px 6px;
    color: #fff;
    display: inline-block;
  }

  #andon_link {
    width: 200px;
    display: inline-block;
  }

  #signup_link {
    background-color: #fff;
    color: #000;
    border-radius: 20px;
    align-items: center;
    padding: 4px 10px;
    display: inline-block;
    @include media-breakpoint-down(lg) {
      margin: 0.4rem auto;
      width: 270px;
    }
  }

  #user_login {
    background-color: #000;
    color: #fff !important;
    border-radius: 20px;
    padding: 4px 10px !important;
    display: inline-block;
    @include media-breakpoint-down(lg) {
      margin: 0.4rem auto;
      width: 270px;
    }
  }

  .link-area {
    @include media-breakpoint-up(lg) {
      max-width: 440px;
      width: 60%;
    }
  }

  @include media-breakpoint-down(lg) {
    .d-flex div {
      margin-bottom: 20px;
    }
    a {
      color: #fff;
    }
  }
}

</style>
<template>
  <BaseHeader></BaseHeader>
  <div id="anchor_top"></div>
  <router-view></router-view>
  <BaseFooter></BaseFooter>
</template>

<script>
import BaseHeader from './components/BaseHeader.vue';
import BaseFooter from './components/BaseFooter.vue';

export default {
  name: 'App',
  components: {
    BaseHeader,
    BaseFooter,
  },
};
</script>

<style lang="scss">
@import "../node_modules/mdb-vue-ui-kit/src/scss/index.free";

#app {
  font-family: Roboto, Helvetica, Arial, sans-serif;
}

h2, h3 {
  color: #A2B51E;
}

.title {
  color: #fff;
  width: 300px;
  font-size: 24px;
  margin: 5% 0 3%;
  transform: skewX(-30deg);
  position: relative;
  padding: 0;
  max-height: 30px;
  height: 100%;

  &::before, &::after, span:before {
    content: '';
    position: absolute;
    background-color: #bdd51b;
    top: 0;
    height: 30px;
  }

  &::before {
    width: 10px;
    left: 263px;
  }

  &::after {
    width: 7px;
    left: 297px;
  }

  span {
    min-width: 235px;
    padding: 0 10px;
    display: inline-block;
    left: 0;
    max-height: 30px;
    line-height: 30px;
    background-color: #b5cc24;
    position: relative;
    box-sizing: content-box;

    &::before {
      width: 10px;
      left: 280px;
    }
  }
}

.button_area {
  .w_btn {
    color: #fff;
    padding: 4px;
    font-size: 2.5rem;
    margin: 2rem 0;
    display: inline-block;
    align-items: center;
    border-radius: 8px;
    background-image: -webkit-gradient(linear, left top, right top, from(#bdd51b), to(#738210));
    background-image: -webkit-linear-gradient(left, #bdd51b 0%, #738210 100%);
    background-image: linear-gradient(to right, #bdd51b 0%, #738210 100%);
    -webkit-box-shadow: 0 5px 10px rgb(0 0 0 / 10%);
    box-shadow: 0 5px 10px rgb(0 0 0 / 10%);
    text-decoration: none;
    line-height: 2.7rem;
    width: 50%;
    max-width: 500px;

    &:hover {
      -webkit-transform: translate(0, 2px);
      transform: translate(0, 2px);
      color: #fff;
      -webkit-box-shadow: 0 8px 15px rgba(0, 0, 0, .2);
      box-shadow: 0 8px 15px rgba(0, 0, 0, .2);
    }

    @include media-breakpoint-down(md) {
      font-size: 1.7em;
    }

    @include media-breakpoint-down(sm) {
      font-size: 1.1em;
    }

    div {
      border: thin solid #fff;
      border-radius: 8px;
      padding: 4px;
    }
  }

  .attention {
    font-size: 1.2rem;
    letter-spacing: 0.1rem;
  }
}


.bg_a {
  background-color: rgb(219, 237, 208) !important;
}

.bg_a_light {
  background-color: rgba(219, 237, 208, 0.4)!important;
}

.bg_b {
  background-color: rgb(214, 228, 244) !important;
}

.bg_b_light {
  background-color: rgba(214, 228, 244, 0.4)!important;
}

.bg_c {
  background-color: rgb(255, 241, 191) !important;
}

.bg_c_light {
  background-color: rgba(255, 241, 191, 0.4)!important;
}

.bg_d {
  background-color: rgb(249, 222, 204) !important;
}

.bg_d_light {
  background-color: rgba(249, 222, 204, 0.4)!important;
}

.bg_f {
  background-color: rgb(205, 211, 221) !important;
}

.bg_f_light {
  background-color: rgba(205, 211, 221, 0.4)!important;
}

.bg_g {
  background-color: rgb(231, 231, 230) !important;
}

.bg_g_light {
  background-color: rgba(231, 231, 230, 0.4)!important;
}

#info_list {
  font-size: 1.2rem;
  @include media-breakpoint-down(md) {
    font-size: 1rem;
  }

  .info_date {
    @include media-breakpoint-up(md) {
      width: 180px;
    }
  }

  ul {
    border-top: 1px solid rgba(0, 0, 0, 0.125);
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  }

  .list-group-item {
    padding: 1.4rem 1.5rem;
  }
}
</style>

import {createRouter, createWebHistory} from 'vue-router';
import TopPage from './views/TopPage.vue';
import FAQ from './views/FAQ.vue';
import WebinarList from './views/WebinarList.vue';
import WebinarDetail from './views/WebinarDetail.vue';
import CompanyDetail from './views/CompanyDetail.vue';
import InfoList from './views/InfoList.vue';
import InfoDetail from './views/InfoDetail.vue';

const routes = [
  {
    path: '/',
    component: TopPage,
  },
  {
    path: '/faq',
    component: FAQ,
  },
  {
    path: '/webinar_list',
    component: WebinarList,
  },
  {
    path: '/webinar/:id',
    component: WebinarDetail,
  },
  {
    path: '/company/:id',
    component: CompanyDetail,
  },
  {
    path: '/info_list',
    component: InfoList,
  },
  {
    path: '/info/:id',
    component: InfoDetail,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,

  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(savedPosition);
        }, 100);
      });
    } else if (to.hash) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          var headH = $('.navbar').outerHeight();
          resolve({
            el: to.hash,
            behavior: 'smooth',
            top: headH,
          });
        }, 300);
      });
    } else {
      return {top: 0, behavior: 'auto'};
    }
  },
});

export default router;
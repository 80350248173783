<template>
  <MDBContainer>
    <section class="my-5">
      <h2 class="text-center mb-4 fs-1">{{ webinar_info.webinar_title }}</h2>
      <p>ID:{{ webinar_info.public_id }}</p>

      <div class="text-center">
      <img
          v-if="!isNullImage && webinar_info.eyecatch_image != ''"
          :src="webinar_info.eyecatch_image"
          alt="ウェビナー紹介写真"
          class="img-fluid mx-auto mb-5"
          style="max-height: 400px;"
      >
      </div>

      <div v-html="webinar_info.webinar_description" id="description"></div>

    </section>

    <section class="button_area text-center">
      <a :href="webinar_info.view_url" class="w_btn">
        <div>視聴する</div>
      </a>
    </section>
  </MDBContainer>

  <section id="speaker_area" v-if="isExistSpeaker">
    <MDBContainer class="p-4">
      <h4>登壇者</h4>

      <MDBCard class="mb-3" v-for="teacher in webinar_info.teacher_info" :key="teacher">
        <MDBRow class="g-0">
          <MDBCol md="4">
            <img v-if="teacher.image" :src="teacher.image"
                 class="my-auto" alt="登壇者のプロフィール写真">
          </MDBCol>
          <MDBCol md="8">
            <MDBCardBody>
              <MDBCardText v-html="teacher.description">
              </MDBCardText>
            </MDBCardBody>
          </MDBCol>
        </MDBRow>
      </MDBCard>
    </MDBContainer>
  </section>

  <section id="company_info">
    <MDBContainer class="mt-5">
      <h3 class="title"><span>企業情報</span></h3>
      <div id="img_area" class="py-3"><img v-if="webinar_info.company_logo_image" :src="webinar_info.company_logo_image" alt="企業ロゴ"></div>

      <MDBTable class="mt-4">
        <tbody>
        <tr>
          <th>企業名</th>
          <td>{{ webinar_info.company_name }}</td>
        </tr>
        <tr>
          <th>所在地</th>
          <td>〒{{ webinar_info.zip }}<br>{{ webinar_info.pref }}{{ webinar_info.address1 }}{{ webinar_info.address2 }}<br>
            {{ webinar_info.address3 }}
          </td>
        </tr>
        <tr>
          <th>プロフィール</th>
          <td v-html="webinar_info.company_profile"></td>
        </tr>
        <tr>
          <th>電話番号</th>
          <td><a v-if="webinar_info.contact_tel"
                 :href="'tel:' + webinar_info.contact_tel">
            {{ webinar_info.contact_tel }}</a></td>
        </tr>
        <tr>
          <th>メールアドレス</th>
          <td>{{ webinar_info.contact_mailaddress }}</td>
        </tr>
        <tr>
          <th>WEBサイト</th>
          <td><a v-if="webinar_info.company_url"
                 :href="webinar_info.company_url">
            {{ webinar_info.company_url }}</a></td>
        </tr>
        </tbody>
      </MDBTable>
    </MDBContainer>
  </section>
</template>

<script>
import {Api, Http} from '@/utiles/ApiConnect';
import {MDBContainer, MDBTable, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBCardText} from 'mdb-vue-ui-kit';
import dayjs from 'dayjs';
import _ from 'lodash';

export default {
  data: function() {
    return {
      webinar_info: {},
    };
  },

  components: {
    MDBContainer,
    MDBTable,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBCardText,
  },

  computed: {
    isNullImage() {
      return _.isNull(this.webinar_info.eyecatch_image);
    },
    isExistSpeaker() {
      return this.webinar_info.teacher_info != null && _.isEmpty(this.webinar_info.teacher_info) === false;
    },
  },

  /***
   * ライフサイクル
   */
  mounted: function() {
    this.getWebinarInfo();
  },

  /***
   * メソッド
   */
  methods: {
    // Webinarテーマ情報の取得
    getWebinarInfo: function() {

      // APIへ接続する。
      Api.connect(
          // 実行するメソッド名
          'Webinar.Info',
          // 引数の指定
          {
            public_id: this.$route.params.id,
          },
      )
          // 実行結果の取得
          .done(function(result) {
            this.webinar_info = result.data;
          }.bind(this));
    },
  },
};
</script>

<style lang="scss">
@import "../../node_modules/mdb-vue-ui-kit/src/scss/index.free";

#info_list {
  font-size: 1.2rem;
  @include media-breakpoint-down(md) {
    font-size: 1rem;
  }

  .webinar_date {
    @include media-breakpoint-up(md) {
      width: 180px;
    }
  }
}

#description {
  font-size: 1.2rem;

  p {
    margin-bottom: 0
  }
}

#base_info table, #company_info table {
  font-size: 1.2rem;
  border-top: 1px solid rgb(224, 224, 224);

  th {
    width: 220px;
    @include media-breakpoint-down(sm) {
      width: 40%;
    }
  }
}

#speaker_area {
  background: #BDD51B;

  img {
    width: 100%;
    height: 250px;
    object-fit: cover;
    background: #eee;
  }
}

#company_info {
  a {
    color: rgb(33, 37, 41);
  }

  #img_area {
    text-align: center;

    img {
      width: 60%;
      max-height: 300px;
      object-fit: contain;
    }
  }
}
</style>
<template>
  <MDBFooter :text="['white', 'lg-start']" bg="">
    <!-- Grid container -->
    <MDBContainer class="pt-4">
      <div class="d-flex flex-column flex-md-row justify-content-between align-items-center">
        <!--Grid column-->
        <div class="mb-4 mb-md-0">
          <ul class="mb-0 lh-lg">
<!--            <router-link to="/">TOP</router-link>-->
<!--            <li><router-link to="/webinar_list">Webinar⼀覧</router-link></li>-->
            <li><router-link to="/info_list">お知らせ⼀覧</router-link></li>
            <li><router-link to="/faq">FAQ</router-link></li>
            <li><a href="https://webinarweek.net/contact/">お問い合わせ</a></li>
            <li><a href="https://greenmolding.org/japanese/about">主催団体紹介ページ</a></li>
            <li><a href="/policy.pdf">利用規約/プライバシーポリシー</a></li>
            <li><a href="https://and-on.net/about">Andonについて</a></li>
            <li><a href="https://webinarweek.net/exhibitor/">出展者専⽤ログイン</a></li>
            <li><a href="https://webinarweek.net/top/">Webinar Week総合TOPページ</a></li>
          </ul>
        </div>
        <!--Grid column-->
        <!--Grid column-->
        <div class="mb-4 mb-md-0">
          <router-link to="/" class="img-link"><img src="@/assets/footerlogo.png" alt="GMWWロゴ"></router-link>
        </div>
        <!--Grid column-->
      </div>
    </MDBContainer>
    <!-- Grid container -->
    <!-- Copyright -->
    <div
        class="text-center p-3"
        style="font-size: 0.7rem"
    >
      All Rights Reserved.
    </div>
    <!-- Copyright -->
  </MDBFooter>


  <div class="fixed-action-btn" id="top_view_btn">
    <router-link  class="btn btn-lg" :to="{hash: '#anchor_top'}">▲</router-link>
  </div>
</template>

<script>
import {
  MDBFooter,
  MDBContainer,
} from 'mdb-vue-ui-kit';

export default {
  components: {
    MDBFooter,
    MDBContainer,
  },
};
</script>

<style lang="scss">
footer {
  background: #bdd51b;

  .container {
    a:not(.img-link) {
      color: #ffffff;
      text-decoration: none;
      position: relative;
      transition: .3s;

      &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        content: '';
        width: 0;
        height: 1px;
        transition: .3s;
        background-color: #fff;
      }

      &:hover::after {
        width: 100%;
      }
    }
  }

  img {
    max-width: 350px;
  }
}

#top_view_btn {
  right: 0.2rem;
  bottom: 1rem;
  padding: 0;

  a {
    color: #399d26;
    border: 1px solid #399d26;
    padding: 10px;
    line-height: 1.8rem;
    background-color: rgb(255 255 255/40%);
    transition: 0.7s;
  }
}
</style>
<template>
  <MDBContainer>
    <section class="my-5">
      <p>ID:{{ info.public_id }}</p>
      <h2 class="text-center mb-4 fs-1">{{ info.title }}</h2>

      <div v-html="info.body" id="info_body" class="my-5"></div>

      <p>{{ dayjs(info.publish_start).format('YYYY年M月D日') }}</p>

      <div class="text-end">
        <router-link to="/info_list">お知らせ一覧に戻る ＞</router-link>
      </div>
    </section>
  </MDBContainer>
</template>

<script>
import {Api, Http} from '@/utiles/ApiConnect';
import {MDBContainer} from 'mdb-vue-ui-kit';
import dayjs from 'dayjs';
import _ from 'lodash';

export default {
  data: function() {
    return {
      // お知らせ
      info: {},
    };
  },

  components: {
    MDBContainer,
  },

  /***
   * ライフサイクル
   */
  mounted: function() {
    this.getNewsInfo();
  },

  /***
   * メソッド
   */
  methods: {
    // お知らせ情報の取得
    getNewsInfo: function() {
      // APIへ接続する。
      Api.connect(
          // 実行するメソッド名
          'Information.Info',
          // 引数の指定
          {
            public_id: this.$route.params.id,
          },
      )
          // 実行結果の取得
          .done(function(result) {
            this.info = result.data;
          }.bind(this));
    },
  },
};
</script>

<style lang="scss">
@import "../../node_modules/mdb-vue-ui-kit/src/scss/index.free";

#info_body {
  font-size: 1.2rem;

  p {
    margin: 0;
  }
}
</style>
<template>
  <MDBContainer>
    <section class="my-5">
      <div id="company_info">
        <div class="text-center">
          <img :src="company.company_logo_image" v-if="company.company_logo_image" alt="企業ロゴ" class="img-fluid">
          <h3 class="mt-5 fs-2">{{ company.company_name }}</h3>
        </div>

        <p class="mb-0">ID:{{ company.public_id }}</p>
        <MDBTable border>
          <tbody>
          <tr>
            <th class="align-middle">プロフィール</th>
            <td v-html="company.company_profile"></td>
          </tr>
          <tr>
            <th class="align-middle">所在地</th>
            <td><span v-if="company.zip != 'n/a'">〒{{ company.zip }}<br></span>
              {{ company.pref }} {{ company.address1 }} {{ company.address2 }}<br>
              {{ company.address3 }}
            </td>
          </tr>
          <tr>
            <th class="align-middle">電話</th>
            <td><a :href="'tel:' + company.contact_tel" v-if="company.contact_tel">{{ company.contact_tel }}</a></td>
          </tr>
          <tr>
            <th class="align-middle">メール</th>
            <td><a :href="'mailto:' + company.contact_mailaddress" v-if="company.contact_mailaddress">{{ company.contact_mailaddress }}</a></td>
          </tr>
          <tr>
            <th class="align-middle">WEBサイト</th>
            <td><a :href="company.company_url" v-if="company.company_url">{{company.company_url}}</a></td>
          </tr>
          </tbody>
        </MDBTable>
      </div>
    </section>
  </MDBContainer>

  <section id="company_webinar">
    <div class="py-2 mb-3 px-3">
      <h3 class="my-5 fs-2 text-center text-white">COMPANY WEBINAR</h3>

      <div class="pb-3">
        <MDBRow :cols="['1', 'md-4']" class="g-4">
          <MDBCol v-for="webinar in webinar_list" :key="webinar.public_id" @click="show_webinar(webinar.public_id)">
            <MDBCard class="h-100">
              <MDBListGroup flush>
<!--                <MDBListGroupItem class="text-muted">{{ dayjs(webinar.webinar_start_at).format('M/D H:mm') }}~</MDBListGroupItem>-->
                <MDBListGroupItem :class="'webinar_genre bg_' + webinar.genre_key">{{ webinar.genre_name }}</MDBListGroupItem>
                <MDBListGroupItem>{{ webinar.webinar_title }}</MDBListGroupItem>
              </MDBListGroup>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </div>
    </div>
  </section>

</template>

<script>
import _ from 'lodash';
import {Api, Http} from '@/utiles/ApiConnect';
import {MDBContainer, MDBTable, MDBCol, MDBRow, MDBCard, MDBListGroup, MDBListGroupItem} from 'mdb-vue-ui-kit';
import dayjs from 'dayjs';

export default {
  data: function() {
    return {
      // 出展者一覧
      company: [],

      // Webinar一覧
      webinar_list: [],
    };
  },

  components: {
    MDBContainer,
    MDBTable,
    MDBCol,
    MDBRow,
    MDBCard,
    MDBListGroup,
    MDBListGroupItem,
  },

  /***
   * ライフサイクル
   */
  mounted: function() {
    this.getCompanyInfo();
    this.getWebinarList();
  },

  /***
   * メソッド
   */
  methods: {
    // Webinar出展者の取得
    getCompanyInfo: function() {
      // APIへ接続する。
      Api.connect(
          // 実行するメソッド名
          'Exhibitor.Info',
          // 引数の指定
          {
            public_id: this.$route.params.id,
          },
      )
          // 実行結果の取得
          .done(function(result) {
            this.company = result.data;
          }.bind(this));
    },

    // Webinar一覧の取得
    getWebinarList: function() {
      // APIへ接続する。
      Api.connect(
          // 実行するメソッド名
          'Webinar.Search',
          // 引数の指定
          {
            exhibitor_id: this.$route.params.id,
          },
      )
          // 実行結果の取得
          .done(function(result) {
            this.webinar_list = _.sortBy(result.data, ['webinar_start_at']);
          }.bind(this));
    },

    show_webinar: function(webinar_id) {
      this.$router.push('/webinar/' + webinar_id);

    },
  },
};
</script>

<style scoped lang="scss">
@import "../../node_modules/mdb-vue-ui-kit/src/scss/index.free";

#company_info {
  img {
    max-width: 60%;
    max-height: 350px;
  }

  table {
    border: 1px solid #A2B51E;
    font-size: 1rem;

    th {
      width: 30%;
      text-align: center;
      background-color: rgba(162, 181, 30, .17);
    }

    tr:nth-child(n+2) td, tr:nth-child(n+2) th {
      border-top: 1px solid rgb(224, 224, 224);
    }
  }
}

#company_webinar {
  background: #BDD51B;
  
  .card{
    cursor: pointer;
  }

  .webinar_genre {
    line-height: 1.1rem;
    font-weight: 300;
    font-size: 0.9rem;
  }
}

</style>
<template>
  <section class="my-5">
    <h2 class="text-center mb-4 fs-1">お知らせ一覧</h2>

    <MDBContainer lg id="info_list">
      <MDBListGroup flush>
        <MDBListGroupItem tag="router-link" :to="'/info/' + info.public_id" action v-for="info in webinar_information" :key="info.public_id">
          <div class="d-flex flex-md-row flex-column">
            <div class="info_date">{{ dayjs(info.publish_start).format('YYYY年M月D日') }}
            </div>
            <div class="info_title flex-grow-1">{{ info.title }}</div>
          </div>
        </MDBListGroupItem>
      </MDBListGroup>
    </MDBContainer>
  </section>
</template>

<script>
import _ from 'lodash';
import {Api, Http} from '@/utiles/ApiConnect';
import {MDBListGroup, MDBListGroupItem, MDBContainer} from 'mdb-vue-ui-kit';
import dayjs from 'dayjs';

export default {
  data: function() {
    return {
      // Webinarお知らせ一覧
      webinar_information: [],
    };
  },

  components: {
    MDBListGroup,
    MDBListGroupItem,
    MDBContainer,
  },

  /***
   * ライフサイクル
   */
  mounted: function() {
    this.getInformations();
  },

  /***
   * メソッド
   */
  methods: {
    // Webinarお知らせの取得
    getInformations: function() {
      // APIへ接続する。
      Api.connect(
          // 実行するメソッド名
          'Information.Search',
          // 引数の指定
          {},
      )
          // 実行結果の取得
          .done(function(result) {
            this.webinar_information = result.data;
          }.bind(this));
    },
  },
};
</script>
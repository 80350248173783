<template>
  <!-- Background image -->
  <div id="top_image" class="bg-image" style=" height: calc(100vh - 90px);">
    <div id="image_wrap" class="d-flex justify-content-center align-items-center flex-column position-relative h-100 mx-auto">
      <img src="@/assets/rogoB.png" alt="" id="top_view_logo">

    </div>
  </div>
  <!-- Background image -->

  <MDBContainer>
    <section id="introduction">
      <h2 class="m-2 mt-4 text-center">Green Molding Webinar Week とは？</h2>
      <p>より少ない資源で、より多くの量と大きな付加価値を生み出す”グリーンモールディング”。<br>
        成形工場の豊かさに欠かせない”グリーンモールディング”の実現を目指し、プラスチック業界の商品や技術を各企業がオンラインセミナーで紹介するイベントです。</p>
    </section>

    <div class="w-100 d-flex justify-content-center p-4 pb-4">
      <figure class="figure text-center">
        <a href="https://greenmolding.org/japanese/" target="_blank" class="text-decoration-none img_link">
          <img
              src="@/assets/logo_greenmolding.png"
              class="figure-img img-fluid rounded shadow-3 mb-3"
              alt="Greenmolding"
              style="max-width: 250px"
          />
          <figcaption class="figure-caption text-center">主催者：グリーン・モールディング・ソリューション協会</figcaption>
        </a>
      </figure>
    </div>

    <section class="mb-4">
      <h3 class="m-2 mt-4 mb-3 text-center">お知らせ一覧</h3>
      <div id="info_list">
        <MDBListGroup flush>
          <MDBListGroupItem tag="router-link" :to="'/info/' + info.public_id" action v-for="info in webinar_information" :key="info.public_id">
            <div class="d-flex flex-md-row flex-column">
              <div class="info_date">{{ dayjs(info.publish_start).format('YYYY年M月D日') }}
              </div>
              <div class="info_title flex-grow-1">{{ info.title }}</div>
            </div>
          </MDBListGroupItem>
        </MDBListGroup>
      </div>
      <div class="text-end mt-2">
        <router-link to="/info_list" class="text-decoration-none">一覧を見る</router-link>
      </div>
    </section>
  </MDBContainer>

  <!-- Background image -->
  <div id="explain" class="position-relative">
    <div class="h3-area float-sm-none float-lg-start">
      <img src="@/assets/header.png" alt="グリーンモールディングウェビナーウィーク">
      <h3 class="mt-3 text-white">開催概要</h3>
    </div>

    <div class="text-area float-sm-none float-lg-end">
      <div>
        <dl>
          <dt>■イベント期間</dt>
          <dd>2022年6月28日（火）〜7月1日（金）</dd>
          <dt>□アーカイブ配信</dt>
          <dd>2022年7月12日（火）〜<br>
            視聴無料(要 会員登録)
          </dd>
          <dt>■主催者</dt>
          <dd>グリーン・モールディング・ソリューション協会</dd>
          <dt>■イベント運営</dt>
          <dd>Andon運営事務局<br><br>
            --企業向けウェビナー検索はAndonで！--
            <a href="https://and-on.net" class="d-block" target="_blank">
              <img src="@/assets/andon_top_b.png"
                   alt="Andonロゴ" id="andon_logo_img"></a>
          </dd>
        </dl>
      </div>
    </div>
  </div>
  <!-- Background image -->

  <MDBContainer class="mt-5">
    <section id="explanation_area">
      <h4 class="title"><span>アーカイブ受講方法</span></h4>
      <h5>STEP1. 受講者登録</h5>
      <p><img src="@/assets/regist_btn.png" alt="《受講者登録》マーク" style="height:2rem;">よりお進み下さい。
        <br>
        <!--        ※ ご入力いただきました情報は、以下の操作をされたときに、該当の参加企業に共有されます。<br>-->
        <!--        ・ウェビナーの視聴予約をしたとき<br>-->
        <!--        ・ウェビナーの視聴をしたとき<br>-->
        <!--        ・ウェビナー詳細ページから資料をダウンロードしたとき<br>-->
      </p>

      <h5>STEP2. 受講（ウェビナー視聴）</h5>
      <p>ウェビナーの詳細ページの<img src="@/assets/view_btn.png" alt="《視聴/予約する》マーク" style="height:2rem;">ボタンをクリックしてご視聴ください。
      </p>
    </section>

    <section class="mt-5 mb-8 button_area text-center">
      <a href="https://webinarweek.net/registration/" target="_blank" class="w_btn">
        <div>受講者登録</div>
      </a>
    </section>

    <section id="timetable-area" class="mt-5">
      <div id="timetable"></div>
      <div id="timetable2"></div>

      <h4 class="title"><span>コンテンツ</span></h4>
      <p>ウェビナーを選択して、詳細ページよりご視聴ください。</p>

      <div class="d-flex align-content-stretch flex-wrap" id="webinar_table">
        <div v-for="genre_name in genre_list" :key="genre_name" style="width: calc(1290px / 6)" :class="'bg_' + genre_name.charAt(0).toLowerCase() + '_light'">
          <div :class="'genre_name bg_' + genre_name.charAt(0).toLowerCase()">
            <p>{{ genre_name }}</p>
          </div>
          <div class="webinar_area">
            <div class="seminar_box" v-for="webinar in webinar_list[genre_name]" :key="webinar.public_id" @click="view_webinar(webinar.public_id)">
              <!--              <div :class="'seminar_type bg_' + webinar.genre_key" v-if="webinar.genre_name">{{ webinar.genre_name }}</div>-->
              <div class="seminar_title">「{{ webinar.webinar_title }}」
              </div>
              <div>{{ webinar.company_name }}</div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section>
      <h4 class="title"><span style="font-size: 20px">非公開ウェビナーについて</span></h4>

      <p>以下のウェビナーは、出展企業の要望により、<br>
        本サイトではアーカイブの公開をしておりません。<br>
        内容等につきましては、出展企業へ直接お問合せください。
      </p>

      <dl>
        <dt>
          <u>昭和電工ガスプロダクツ株式会社</u>
        </dt>
        
        <dd>①ドライアイス洗浄で金型をいつもキレイに保つ ②ＵＦＢ水による金型冷却水の改善<br>
        </dd>

        <dt>
          <u>Spiral Logic Limited</u>
        </dt>
        <dd>エンプラ専用リサイクル装置Raptor22によるプラゴミ削減と資源化<br>
          新金型断熱システムTamaGONによる成形工場の省エネ・脱炭化
        </dd>

        <dt><u>日精樹脂工業株式会社</u></dt>
        <dd>成形品の高付加価値化を推進する最新技術紹介</dd>

        <dt><u>トレクセルジャパン株式会社</u></dt>
        <dd>環境負荷低減と発泡成形 (MuCell基礎編)<br>
          環境負荷低減と発泡成形による本当の軽量化 (MuCell応用編) <br>
          ※トレクセルジャパン株式会社は、自社にてアーカイブ公開中です。視聴希望の方は直接お問合せください。
        </dd>

        <dt><u>ハスキー株式会社</u></dt>
        <dd>
        医療機器製造の為の統合したシステムアプローチ INTEGRATED SYSTEM APPROACH FOR MEDICAL DEVICE MANUFACTURING<br>
        ホットランナ金型コントローラ/ウルトラショットMold Controllers Ultrashot Integration<br>
        ホットランナ バルブゲートテクノロジーHR-Technologies Valve Gating<br>
        バイオ樹脂/ホットランナテクノロジー HR-Technologies Bio Resin<br>
        </dd>

      </dl>

    </section>


    <section id="partner_logo">
      <h4 class="title"><span>プラチナpartner</span></h4>
      <div class="row">
        <div class="col-lg-4 col-12 my-4 my-md-2 my-lg-0">
          <router-link to="/company/BS_SAESQDAl">
            <img src="@/assets/corporate_logo/spirallogic.png" alt="spirallogic">
          </router-link>
        </div>
        <div class="col-lg-4 col-12 my-4 my-md-2 my-lg-0">
          <router-link to="/company/BS_eb4LoAQF">
            <img src="@/assets/corporate_logo/Sodick.png" alt="Sodick">
          </router-link>
        </div>
        <div class="col-lg-4 col-12 my-4 my-md-2 my-lg-0">
          <router-link to="/company/BS_6mUoN6Sh">
            <img src="@/assets/corporate_logo/Matsui.png" alt="松井製作所">
          </router-link>
        </div>
      </div>
    </section>
  </MDBContainer>

  <section id="company_wrap" class="mt-5">
    <MDBContainer class="pt-3 pb-4">
      <h4 class="title"><span>出展企業一覧</span></h4>

      <div class="row text-center">
        <div class="col-lg-4 col-12 col-md-6 my-md-2 my-1"
             v-for="company in webinar_exhibitor" :key="company"><span>{{ company }}</span></div>
      </div>
    </MDBContainer>
  </section>
</template>

<script>
import _ from 'lodash';
import {Api, Http} from '@/utiles/ApiConnect';
import {MDBContainer, MDBListGroup, MDBListGroupItem} from 'mdb-vue-ui-kit';
import '@/utiles/jquery.simpleTicker';
import dayjs from 'dayjs';

export default {
  data: function() {
    return {
      // テーマ情報
      thema_info: {
        title: '',
        description: '',
      },

      // Webinar一覧
      webinar_list: [],

      // Webinarお知らせ一覧
      webinar_information: [],

      // Webinar事業一覧
      webinar_exhibitor: [
      '株式会社環境機能設備',
      '昭和電工ガスプロダクツ株式会社',
      'Spiral Logic Limited',
      '株式会社セイロジャパン',
      '株式会社ソディック',
      'トレクセルジャパン株式会社',
      '⽇精樹脂⼯業株式会社',
      'ニチエツ株式会社',
      '株式会社⽇本製鋼所',
      'ハスキー株式会社',
      'Frigel Asia Pacific Co., Ltd',
      'ブルーエンジニアリング株式会社',
      '株式会社松井製作所'
      ],

      genre_list: [],
    };
  },

  components: {
    MDBContainer,
    MDBListGroup,
    MDBListGroupItem,
  },

  /***
   * ライフサイクル
   */
  mounted: function() {
    this.getWebinarList();
    this.getInformation();
    // this.getExhibitor();
  },

  /***
   * メソッド
   */
  methods: {
    // Webinar一覧の取得
    getWebinarList: function() {
      // APIへ接続する。
      Api.connect(
          // 実行するメソッド名
          'Webinar.Search',
          // 引数の指定
          {},
      )
          // 実行結果の取得
          .done(function(result) {
            let webinar_list = {};
            _.forEach(result.data, function(obj) {
              let key_genre = obj.genre_name;
              if (key_genre in webinar_list) {
                webinar_list[key_genre].push(obj);
              } else {
                webinar_list[key_genre] = [obj];
              }
            });

            let genre_list = _.keys(webinar_list).sort();

            this.webinar_list = webinar_list;
            this.genre_list = genre_list;
          }.bind(this));
    },

    // Webinarお知らせの取得
    getInformation: function() {
      // APIへ接続する。
      Api.connect(
          // 実行するメソッド名
          'Information.Search',
          // 引数の指定
          {},
      )
          // 実行結果の取得
          .done(function(result) {
            this.webinar_information = _.slice(result.data, 0, 3);
          }.bind(this));
    },

    // Webinar事業者一覧の取得
    getExhibitor: function() {
      // APIへ接続する。
      Api.connect(
          // 実行するメソッド名
          'Exhibitor.Search',
          // 引数の指定
          {},
      )
          // 実行結果の取得
          .done(function(result) {
            let webinar_exhibitor =
                _.sortBy(
                    result.data, ['company_name_read']);
            this.webinar_exhibitor = webinar_exhibitor;
          }.bind(this));
    },

    // Webinar詳細画面へ
    view_webinar(webinar_id) {
      this.$router.push('/webinar/' + webinar_id);
    },

    // news詳細へ
    view_info(info_id) {
      this.$router.push('/info/' + info_id);
    },

    // 事業者詳細へ
    view_company: function(company_id) {
      this.$router.push('/company/' + company_id);
    },
  },
};
</script>
<style lang="scss">
@import "../../node_modules/mdb-vue-ui-kit/src/scss/index.free";

.container a {
  text-decoration: underline;
  color: #4f4f4f;
}

a.img_link:hover {
  opacity: 0.8;
}

#top_image {
  background-image: url(@/assets/mv.jpg);
  @include media-breakpoint-down(md) {
    height: 50vh !important;
  }
}

#top_view_logo {
  width: 100%;
  max-width: 100%;
  display: block;
  //margin: 0 20%;
  @include media-breakpoint-up(sm) {
    max-width: 80%;
  }
  @include media-breakpoint-up(lg) {
    max-width: 60%;
  }
}

#image_wrap {
  max-width: 90%;
  width: 1200px;
}

.date {
  position: absolute;
  bottom: 80px;
  color: rgba(255, 255, 255, 0.9);
  font-weight: bold;
  font-size: 3.5rem;
  line-height: 1.8rem;
  text-shadow: 2px 1px rgb(0 0 0 / 40%);
  margin: 0;
  width: 100%;
  //max-width: 410px;
  text-align: center;

  @include media-breakpoint-down(md) {
    font-size: 2rem;
    bottom: 60px;
  }

}

//@include media-breakpoint-down(sm) {
//  .date {
//    max-width: 100%;
//    left: unset;
//    font-size: 3rem;
//    margin: 1% 0;
//  }
//}

/* ティッカー */
.info {
  position: absolute;
  bottom: 1rem;
  width: 1200px;
  max-width: 90%;
  margin: 0 auto;
  line-height: 1.8rem;
  box-sizing: content-box;

  .ticker {
    margin: 0 auto;
    padding: 5px;
    text-align: left;
    border: 3px solid rgb(0 0 0/60%);
    position: relative;
    overflow: hidden;
    background-color: #000;
    border-radius: 60px;
    height: 1.4rem;
    box-sizing: content-box;

    ul, li, ul::before {
      list-style: none;
      box-sizing: content-box;
    }

    ul {
      width: calc(100% - 80px); /* 6/2修正箇所 */
      position: relative;
      margin-left: 90px; /* 6/2修正箇所 */

      &::before {
        content: 'INFO'; /* 6/2修正箇所 */
        color: #399d26;
        position: absolute;
        left: -72px; /* 6/2修正箇所 */
        border-right: 1px solid #399d26;
        padding-right: 1rem;
        font-size: 1.2rem;
      }

      li {
        width: 100%;
        display: none;
        color: #fff;
        overflow: hidden;
        white-space: nowrap;
        cursor: pointer;
      }
    }
  }
}

#introduction {
  h2 {
    color: #A2B51E;
    font-weight: normal;
  }

  p {
    font-size: 1.2rem;
    letter-spacing: 0.2rem;
    line-height: 2.5rem;
  }
}

#explain {
  background-image: url("@/assets/bg_01.jpg");
  background-size: cover;
  background-position: center;
  width: 100%;
  position: relative;
  overflow: hidden;
  z-index: 0;
  clear: both;

  .h3-area {
    margin-top: 3%;
    padding: 6% 3%;
    background: #BDD51B;
    width: 33%;
    border-radius: 0 3vh 3vh 0;
    box-shadow: 10px 10px 10px #00000040;
    z-index: 200;
    color: #fff;
    text-align: center;
    box-sizing: content-box;
    position: relative;

    img {
      width: 80%;
      @include media-breakpoint-down(lg) {
        width: 50%;
      }
    }

    @include media-breakpoint-down(lg) {
      border-radius: 4vh;
      margin: 2% 2% 0 2%;
      width: 90%;
      padding: 2% 3%;
    }
  }

  .text-area {
    margin: -14% auto 5% auto;
    background: #F2F7D1;
    width: 65%;
    border-radius: 0 0 0 3vh;
    padding: 2%;
    z-index: 100;
    box-sizing: content-box;

    div {
      margin: 3% auto;
      max-width: 70%;
      box-sizing: content-box;
    }

    @include media-breakpoint-down(lg) {
      width: 92%;
      margin: 2%;
      border-radius: 4vh;
    }

    #andon_logo_img {
      width: 100%;
      @include media-breakpoint-up(sm) {
        max-width: 60%;
      }
    }

    a:hover img {
      opacity: 0.8;
    }
  }
}

#explanation_area {
  h5 {
    font-size: 20px;
    font-weight: 700;
    margin: 10px 0 5px;
    border-left: 4px solid #738210;
    padding-left: 0.5rem;
  }

  p {
    margin-left: 1rem;
  }
}

#webinar_table {

  .genre_name {
    text-align: center;
    height: 100px;
    font-family: Anton;

    p {
      padding: 20px 10px;
      margin: 0;
      font-size: 1.5rem;
      line-height: 1.8rem;
    }
  }

  .webinar_area {
    padding: 3px 5px;

    .seminar_box {
      border: 1px solid #8DAB00;
      border-radius: 10px;
      padding-bottom: 5px;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      background-color: white;
      cursor: pointer;
      margin-bottom: 10px;

      div {
        padding: 3px 10px;
        line-height: 1.2rem;
      }

      .seminar_type {
        line-height: 1rem;
        font-weight: 300;
      }

      .seminar_title {
        font-weight: 700;
      }
    }
  }
}

.timetable {
  table-layout: fixed;

  thead {
    font-family: Anton;

    th {
      padding: 20px 10px;
      font-size: 1.5rem;
      line-height: 1.8rem;
      text-align: center;
      vertical-align: middle;
      width: 220px;
      color: #333;

      &:nth-child(odd) {
        //background: #9BBB00;
      }

      &:nth-child(even) {
        //background: #8DAB00;
      }
    }
  }

  tbody {
    td:nth-child(even) {
      //background: #EBF6AD;
    }

    td {
      padding: 3px 5px;
      vertical-align: top;
    }
  }

  .seminar_box {
    border: 1px solid #8DAB00;
    border-radius: 10px;
    padding-bottom: 5px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background-color: white;
    cursor: pointer;
    margin-bottom: 10px;

    div {
      padding: 3px 10px;
      line-height: 1.2rem;
    }

    .seminar_type {
      line-height: 1rem;
      font-weight: 300;
    }

    .seminar_title {
      font-weight: 700;
    }
  }
}

#partner_logo {
  div.row div {
    max-height: 110px;

    img {
      width: 100%;
      height: 140px;
      object-fit: contain;
      mix-blend-mode: multiply;
      cursor: pointer;
    }

    a:hover {
      opacity: 0.3;
      transition: 0.4s;
    }
  }

  @include media-breakpoint-between(md, lg) {
    div.row div {
      margin: 0;

      img {
        height: 90px;
      }
    }
    div.row div:first-of-type img {
      height: 170px;
      margin-top: -40px;
    }
  }

  @include media-breakpoint-down(md) {
    div.row {
      margin-top: -70px;
    }
    div img {
      height: 85px;
    }
    div.row div:first-of-type img {
      height: 170px;
      margin-top: -5px;
    }
  }

  @include media-breakpoint-down(sm) {
    div.row div:first-of-type img {
      margin-top: 15px;
    }
  }

}

#company_wrap {
  background: #F2F7D1;

  // div.row div {
  //   cursor: pointer;

  //   span {
  //     position: relative;

  //     &::after {
  //       position: absolute;
  //       bottom: -4px;
  //       left: 0;
  //       content: '';
  //       width: 100%;
  //       height: 2px;
  //       background: #617501;
  //       transform: scale(0, 1);
  //       transform-origin: center top;
  //       transition: transform .3s;
  //     }

  //     &:hover::after {
  //       transform: scale(1, 1);
  //     }
  //   }
  // }
}

#info_list {
  a {
    text-decoration: none;
  }
}
</style>

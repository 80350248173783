<template>
  <section class="my-5">
    <h2 class="text-center mb-4 fs-1">よくある質問</h2>

    <MDBContainer lg id="faq_list">
      <MDBListGroup flush>
        <MDBListGroupItem>
          <dl>
            <dt>Q. ウェビナー受講のために、受講者登録は必須ですか？</dt>
            <dd>A. 受講者登録は必須です。一度登録していただいた情報は、他のwebinar weekへ参加する際にもご利用いただけます。</dd>
          </dl>
        </MDBListGroupItem>
        <MDBListGroupItem>
          <dl>
            <dt>Q. 受講者登録で登録した内容は変更できますか？</dt>
            <dd>A. 登録が完了しますと、マイページが開設されます。ご登録いただいた内容は、マイページより修正できます。</dd>
          </dl>
        </MDBListGroupItem>
        <MDBListGroupItem>
          <dl>
            <dt>Q. マイページにはどこから行けますか？</dt>
            <dd>A. webinar weekイベントサイト上部の《入場者用ログイン》よりアクセスできます。</dd>
          </dl>
        </MDBListGroupItem>
        <MDBListGroupItem>
          <dl>
            <dt>Q. 退会することはできますか？</dt>
            <dd>A. 登録の削除（退会）はマイページよりお手続きいただけます。</dd>
          </dl>
        </MDBListGroupItem>
        <MDBListGroupItem>
          <dl>
            <dt>Q. 個人での登録は可能ですか？</dt>
            <dd>A. webinar weekはビジネスマッチングを目的としたイベントですので、ビジネス目的に限り、ご登録可能です。</dd>
          </dl>
        </MDBListGroupItem>
        <MDBListGroupItem>
          <dl>
            <dt>Q. ウェビナー視聴の推奨環境はありますか？</dt>
            <dd>A. 下記を推奨しております。<br>
              ■PC<br>
              推奨環境：Google Chrome（最新版）<br>
              動作環境：Microsoft Edge・Safari（いずれも最新版）<br>
              ※ Internet Explorer はサポート外です。<br>
              ■スマートフォン<br>
              iOS：Google Chrome・Safari<br>
              Android：Google Chrome<br>
              ※各OSは最新バージョン推奨です。
            </dd>
          </dl>
        </MDBListGroupItem>
        <MDBListGroupItem>
          <dl>
            <dt>Q. ウェビナーを視聴するツールは何ですか？</dt>
            <dd>A. 配信ツールはウェビナーごとに異なり、ZOOM、Teams、YouTubeなどとなります。<br>
              個別の配信方法については、当サイトの各ウェビナー詳細ページに記載しております。
            </dd>
          </dl>
        </MDBListGroupItem>
        <MDBListGroupItem>
          <dl>
            <dt>Q. ZOOMはどのように視聴するのでしょうか。</dt>
            <dd>A. こちらをご参照ください。<br>
              <a href="https://support.zoom.us/hc/ja/articles/201362193-Zoom-ビデオコールへの参加" target=”_blank”>https://support.zoom.us/hc/ja/articles/201362193-Zoom-ビデオコールへの参加</a><br>
              ※アカウントの作成やアプリのダウンロードなしでもご利用いただけます。
            </dd>
          </dl>
        </MDBListGroupItem>
        <MDBListGroupItem>
          <dl>
            <dt>Q. Teamsはどのように視聴するのでしょうか。</dt>
            <dd>A. こちらをご参照ください。<br>
              <a href="https://support.microsoft.com/ja-jp/office/teams-での会議に参加する-1613bb53-f3fa-431e-85a9-d6a91e3468c9" target=”_blank”>https://support.microsoft.com/ja-jp/office/teams-での会議に参加する-1613bb53-f3fa-431e-85a9-d6a91e3468c9</a><br>
              ※アカウントの作成やアプリのダウンロードなしでもご利用いただけます。
            </dd>
          </dl>
        </MDBListGroupItem>
        <MDBListGroupItem>
          <dl>
            <dt>Q. webinar week参加登録方法を教えてください</dt>
            <dd>A. 招待コードをご用意の上、TOPページの《受講者登録》をクリックして、受講登録ページよりお手続きください。</dd>
          </dl>
        </MDBListGroupItem>
        <MDBListGroupItem>
          <dl>
            <dt>Q. 招待コードとはなんですか？</dt>
            <dd>A. webinar weekを受講するために必要なイベント招待コードです。</dd>
          </dl>
        </MDBListGroupItem>
        <MDBListGroupItem>
          <dl>
            <dt>Q. 招待コードはどこで入手できますか？</dt>
            <dd>A. 招待コードは、各参加企業または、こちらのWEBページよりご入手ください。<br>
              <span class="fw-bolder">松井製作所</span><br>
              「Green Molding Webinar Week【無料ご招待コード】 お申込み受付けフォーム」<br>
              <a href="https://form.k3r.jp/matsui_mfg/202206280701" target="_blank">https://form.k3r.jp/matsui_mfg/202206280701</a><br>
              <br>
              <span class="fw-bolder">セイロジャパン</span><br>
              「Green Molding Webinar Weekに伴うMoldex3Dセミナーのご案内」<br>
              <a href="https://www.saeilo.co.jp/media/event/moldex3d_seminar20220628" target="_blank">https://www.saeilo.co.jp/media/event/moldex3d_seminar20220628</a></dd>
          </dl>
        </MDBListGroupItem>
        <MDBListGroupItem>
          <dl>
            <dt>Q. 登録したIDは複数人で共用できますか？</dt>
            <dd>A. 参加企業と直接やりとりが発生する可能性もございますので、IDの共有はできません。参加くださる皆様それぞれにてご登録ください。</dd>
          </dl>
        </MDBListGroupItem>
        <MDBListGroupItem id="direct">
          <dl>
            <dt>Q. ウェビナー視聴の予約はできますか？</dt>
            <dd>A. 各ウェビナー詳細ページの《視聴/予約する》を事前にクリックすることで、視聴予約ができます。
              <br>
              《予約方法》<br>
              <ul id="view_step">
                <li><router-link to="/#timetable">TOPページのタイムテーブル</router-link>より任意のウェビナーを選択（クリック）<br>
                  <img src="@/assets/explanation_image.png" alt="" style="max-width: 300px; width: 100%;margin-bottom: 20px"></li>
                <li>ウェビナー詳細ページの<img src="@/assets/view_btn.png" alt="《視聴/予約する》マーク" style="height:2rem;">をクリック</li>
                <li>画面の指示に従って予約を完了させてください。</li>
              </ul>
            </dd>
          </dl>
        </MDBListGroupItem>
        <MDBListGroupItem>
          <dl>
            <dt>Q. ウェビナーはどうやって視聴しますか？</dt>
            <dd>A. 各ウェビナーの開始10分前から、配信用URLにアクセスできます。
              <br>
              《視聴方法》<br>
              <ul id="view_step" class="mb-2">
                <li><router-link to="/#timetable">TOPページのタイムテーブル</router-link>より任意のウェビナーを選択（クリック）<br>
                  <img src="@/assets/explanation_image.png" alt="" style="max-width: 300px; width: 100%;margin-bottom: 20px"></li>
                <li>ウェビナー詳細ページの<img src="@/assets/view_btn.png" alt="《視聴/予約する》マーク" style="height:2rem;">をクリック</li>
                <li>画面の指示に従ってご視聴ください。</li>
              </ul>
              ※ 予約したウェビナーは、マイページの《受講(予約)履歴》 <img src="@/assets/find_icon.png" alt="マイページマーク" style="height:2rem;">マークからもアクセスできます。
            </dd>
          </dl>
        </MDBListGroupItem>
        <MDBListGroupItem>
          <dl>
            <dt>Q. ウェビナー開催日時に視聴ができない場合、後日視聴はできませんか？</dt>
            <dd>A. ほとんどのウェビナーはアーカイブ動画を保持しております。アーカイブの公開方法は参加企業ごとに設定していますので、個別にお問合せください。</dd>
          </dl>
        </MDBListGroupItem>
        <MDBListGroupItem>
          <dl>
            <dt>Q. webinar week参加企業から連絡が来ることはありますか？</dt>
            <dd>A. 以下の操作をされた場合、webinar week事務局より該当ウェビナーを開催した企業へ、受講者情報が共有します。その情報をもとに、開催企業より直接コンタクトがある場合がございます。<br>
              また、ウェビナー実施時に開催企業独自にアンケートを実施する場合があります。このアンケートに回答いただいた場合も、直接コンタクトがある可能性がございます。<br>
              なお、参加者ご自身がウェビナーの視聴予約や視聴登録をしていない企業へは、参加者の情報は公開されません。<br>
              <br>
              ＜出展企業が受講者情報を取得するタイミング＞<br>
              ・（受講者が）ウェビナーの視聴予約をしたとき<br>
              ・（受講者が）ウェビナーの視聴をしたとき<br>
              ・（受講者が）ウェビナー詳細ページから資料をダウンロードしたとき
            </dd>
          </dl>
        </MDBListGroupItem>
        <MDBListGroupItem>
          <dl>
            <dt>Q. webinar week参加企業へ直接コンタクトを取ることはできますか？</dt>
            <dd>A. 参加企業情報に記載されている連絡先へ直接ご連絡ください。連絡先がわからない場合は、マイページのお問い合わせフォームより事務局へご連絡ください。</dd>
          </dl>
        </MDBListGroupItem>
        <MDBListGroupItem>
          <dl>
            <dt>Q. webinar weekに企業として参加することはできますか？（webinar weekを開催したい）</dt>
            <dd>A. 事務局にて承ります。お問い合わせフォームよりお問合せください。</dd>
          </dl>
        </MDBListGroupItem>
        <MDBListGroupItem>
          <dl>
            <dt>Q. 自社のウェビナー実施のサポートを依頼することはできますか？</dt>
            <dd>A. 事務局にて承ります。お問い合わせフォームよりお問合せください。</dd>
          </dl>
        </MDBListGroupItem>
      </MDBListGroup>
    </MDBContainer>
  </section>
</template>

<script>
import {MDBContainer, MDBListGroup, MDBListGroupItem} from 'mdb-vue-ui-kit';

export default {
  components: {
    MDBContainer,
    MDBListGroup,
    MDBListGroupItem,
  },
};
</script>

<style lang="scss">
#faq_list {
  > ul {
    border-top: 1px solid rgba(0, 0, 0, 0.125);
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  }

  dl {
    margin-top: 1rem;
  }

  dd {
    padding-left: 1.2rem;
    text-indent: -1.2rem;
    margin-bottom: 0;
  }

  a {
    word-wrap: break-word !important;
    word-break: break-word !important;
  }

  ul#view_step {
    list-style: decimal;
    li{
      padding-left: 30px;
    }
  }
}
</style>

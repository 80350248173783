/*****************************************************************************/
//
// API接続クラスサンプル
// ※ jQueryライブラリ必須
//
import $ from 'jquery'

// API接続URL
const API_URL = 'https://webinarweek.net';

// エンドポイント
const END_POINT = '/website/api/v1.0.json';

// ウェビナー見本市テーマID
const THEMA_ID = 'TH_6RO47nep';

class ApiConnect {
	//コンストラクタ
	constructor() {
	}

	//API接続
	connect(method, params) {
		// 非同期処理
		let defConnect = new $.Deferred();
		
		// POSTパラメータ整形
		let data = {
			THEMA_ID: THEMA_ID,
			METHOD: method,
			PARAMS: JSON.stringify(params),
		}

		// HTTPリクエスト設定
		let option = {
			url: API_URL + END_POINT,
			type: 'POST',
			dataType: 'json',
			cache: false,
			data: data,
			crossDomain:true,
		};
		
		// Ajax接続
		$.ajax(option)

		// Ajaxリクエストが成功した時
		.done(function(result){
			// 正常終了
			if(result.status == '200')
			{
				defConnect.resolve(result);
			}
			// 異常終了
			else
			{
				defConnect.reject(result);
			}
		})
		
		// Ajaxリクエストが失敗した時
		.fail(function(result){
			defConnect.reject(result);
		})
		
		return defConnect.promise();
	}
}
const Api = new ApiConnect();


/*****************************************************************************/
//
// HTTP関連 Util
//
class HttpUtil {
	//コンストラクタ
	constructor(locale, currencyCode) {
		this.locale = locale;
	}
	query(key, url) {
		if (!url) url = window.location.href;
		
		key = key.replace(/[[]]/g, "\\$&");
		var regex = new RegExp("[?&]" + key + "(=([^&#]*)|&|#|$)"),
			results = regex.exec(url);
		if (!results) return null;
		if (!results[2]) return '';
		return decodeURIComponent(results[2].replace(/\+/g, " "));
	}
}
const Http = new HttpUtil();

export {Api, Http}